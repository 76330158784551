<i18n>
ru:
  choose: Выбрать подарок
  title: Выберите подарок!
  subtitle: 'Закажите еще на {sum} для того чтобы получить {item}'
ua:
  choose: Вибрати подарунок
  title: Виберіть подарунок!
  subtitle: 'Замовте ще на {sum} щоб отримати {item}'
us:
  choose: Choose gift
  title: Choose a gift!
  subtitle: 'Order {sum} more to receive {item}'
</i18n>

<template>
  <div
    v-if="showGiftsRanged && appConfig.VueSettingsPreRun.GiftsRangedLayout === 'Shire'"
    class="v-w-100"
  >
    <div class="v-cart-gifts-ranged v-w-100 v-mb-sm">
      <div class="v-row v-flex-row-no-wrap">
        <div
          class="v-ml-xs v-cart-gifts-ranged-image v-col-4 v-col-md-3 v-col-lg-2 v-d-flex v-align-items-center v-justify-content-center"
        >
          <arora-nuxt-image
            v-if="imageExists(appConfig.VueSettingsPreRun.GiftsRangedCustomIcon)"
            :alt="translate('giftsRanged.title')"
            :image="appConfig.VueSettingsPreRun.GiftsRangedCustomIcon"
          />
          <icon-menu-ranged-present-banner v-else />
        </div>
        <div class="v-d-flex v-flex-row v-col-8 v-col-md-9 v-col-lg-10">
          <div
            class="v-cart-gifts-ranged-title v-flex-100 v-mb-sm"
            v-html="translate('giftsRanged.title')"
          />
          <div
            v-if="currentGift && currentProduct && currentGift.AvailableFromCartSum > cartToPayWithMoney"
            class="v-cart-gifts-ranged-subtitle v-flex-100 v-mb-sm"
          >
            <i18n-t
              class="v-d-flex"
              keypath="giftsRanged.subtitle"
              scope="global"
              tag="span"
            >
              <template #sum>
                <common-currency
                  class="v-mr-xxs v-ml-xxs"
                  :amount="currentGift.AvailableFromCartSum - cartToPayWithMoney"
                />
              </template>
              <template #item>
                {{ currentProduct.Name }}
              </template>
            </i18n-t>
          </div>
          <div class="v-cart-gifts-ranged-bars v-d-flex v-flex-row v-flex-row-no-wrap v-scrollbar">
            <div
              v-for="(milestone, index) in milestones"
              :key="`gifts-ranged-bars-${milestone}-${index}`"
              class="v-cart-gifts-ranged-bar-single v-d-flex v-flex-column"
            >
              <common-progress-bar
                class="v-mb-xxs"
                :percent="
                  currentIndex < index ? 0 : (paySeparated[index] / milestoneSeparated[index]) * 100
                "
              />
              <div
                class="v-cart-gifts-ranged-bar-text v-d-flex v-flex-row-no-wrap v-align-items-center"
                :class="{
                  disabled: currentIndex < index
                }"
              >
                <div class="v-elements-overlay">
                  <div class="v-cart-gifts-icon-single v-overlay-element">
                    <icon-menu-ranged-present />
                  </div>
                  <div
                    class="v-cart-gifts-icon-single v-overlay-element"
                    :class="{
                      rotated: milestone < cartToPayWithMoney
                    }"
                  >
                    <icon-menu-ranged-present-lid />
                  </div>
                </div>

                <common-currency
                  class="v-d-flex v-align-items-center"
                  :amount="milestone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <arora-button
      v-if="!appConfig.VueSettingsPreRun.GiftsRangedHideButton"
      :label="translate('giftsRanged.choose')"
      @click="callForPopup"
    />
  </div>
</template>

<script setup lang="ts">
import type { GiftRanged, Product } from '~types/menuStore'

const menuStore = useMenuStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { imageExists } = useImageInfo()
const { translate } = useI18nSanitized()
const { isLinkContains } = useUrl()
const appConfig = useAppConfig()

const showGiftsRanged = computed<boolean>(() => {
  if (gifts.value.length === 0) return false

  if (isLinkContains('/menu'))
    return (
      appConfig.VueSettingsPreRun.GiftsRangedShowInMenuAsInCart &&
      appConfig.VueSettingsPreRun.GiftsRangedShowInMenu
    )

  if (
    isLinkContains(appConfig.VueSettingsPreRun.Links.CartFirstStep) ||
    isLinkContains(appConfig.VueSettingsPreRun.Links.CartSecondStep)
  )
    return appConfig.VueSettingsPreRun.GiftsRangedShowInCart

  return false
})

onMounted(() => {
  clientStore.initClientState()
  menuStore.initGiftsRanged()
})

const gifts = ref<GiftRanged[]>([])
const milestones = ref<number[]>([])
const paySeparated = ref<number[]>([])
const milestoneSeparated = ref<number[]>([])

const currentIndex = computed<number>(() => {
  let result = 0

  for (const milestone of milestones.value) {
    if (milestone >= cartToPayWithMoney.value) break

    result++
  }

  return result
})

const cartToPayWithMoney = computed<number>(
  () => clientStore.ClientState.data?.Cart?.ToPayWithMoneyWithoutExcludedItems ?? 0
)

const currentGift = computed<GiftRanged | null>(() => {
  let result: GiftRanged | null = null
  for (const gift of gifts.value) {
    result = gift

    if (gift.AvailableFromCartSum > cartToPayWithMoney.value) break
  }

  return result
})

const currentProduct = computed<Product | null | undefined>(() => {
  if (currentGift.value) {
    return appConfig.Products.find((product) => product.ID === currentGift.value!.GiftProductID)
  }
  return null
})

function callForPopup(): void {
  popupStore.openPopup({
    popupName: 'menuGiftsPopup',
    popupClosable: true,
    popupProperties: new Map<string, unknown>([
      ['allowMultiple', menuStore.GiftsRanged.data?.AllowMultipleGifts],
      ['gifts', menuStore.GiftsMultiple.data?.Gifts],
      ['type', 'ranged']
    ])
  })
}
function paySeparatedChange(): void {
  const paySeparatedLocal = [] as number[]

  for (let index = 0; index < milestones.value.length; index++) {
    if (index === 0) paySeparatedLocal.push(cartToPayWithMoney.value)
    else paySeparatedLocal.push(cartToPayWithMoney.value - milestones.value[index - 1])
  }

  paySeparated.value = [...paySeparatedLocal]
}
watch(
  () => menuStore.GiftsRanged.data?.Gifts,
  (newValue, oldValue) => {
    if (
      newValue &&
      newValue.map((g) => g.GiftProductID).join('-') !==
        (oldValue ?? []).map((g) => g.GiftProductID).join('-')
    ) {
      milestones.value = newValue
        ? [...new Set(newValue.map((item) => item.AvailableFromCartSum))].sort((a, b) => {
            return a - b
          })
        : []
      gifts.value = (newValue ?? []).sort((a: GiftRanged, b: GiftRanged) => {
        return a.AvailableFromCartSum > b.AvailableFromCartSum ? 1 : -1
      })
      paySeparatedChange()
      const milestoneSeparatedLocal = [] as number[]

      let previousMilestone = 0

      for (const milestone of milestones.value) {
        milestoneSeparatedLocal.push(milestone - previousMilestone)
        previousMilestone = milestone
      }

      milestoneSeparated.value = [...milestoneSeparatedLocal]
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => cartToPayWithMoney.value,
  () => {
    paySeparatedChange()
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-cart-gifts-ranged {
  background: variables.$FormBackground;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;

  padding: 8px;

  .v-cart-gifts-ranged-title {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: variables.$TextSizeH2;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .v-cart-gifts-ranged-subtitle {
    flex-wrap: wrap;
    font-size: 1.1rem;

    &-left,
    &-right {
      vertical-align: bottom;
    }

    &-right {
      white-space: break-spaces;

      @include mixins.md {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .v-cart-gifts-ranged-bars {
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    .v-cart-gifts-ranged-bar-single {
      flex-grow: 1;
      flex-basis: 0;

      .v-cart-gifts-ranged-bar-text {
        gap: 5px;

        .v-currency-wrapper {
          font-size: variables.$TextSizeLabel;
        }

        .v-cart-gifts-icon-single {
          @include mixins.trans;

          &.rotated {
            rotate: -25deg;
          }

          .v-ranged-present {
            width: 14px;
            height: 14px;

            path {
              fill: variables.$BodyTextColorLight;
            }
          }
        }
      }
    }
  }
}
</style>
